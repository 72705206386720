<template>
  <v-card>
    <v-card-text>
      <v-row align="center" class="mb-2">
        <v-col>
          <label class="text-h6">Shipping Address</label>
        </v-col>
        <v-col>
          <v-checkbox
            v-model="sameAsBilling"
            @change="toggleSameAsBilling"
            hide-details
            label="Same as Billing"
            class="shrink mr-2 mt-0"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-text-field
        v-model="formData.shippingStreet"
        :rules="[rules.required]"
        label="Street"
        maxlength="100"
        required
        dense
      ></v-text-field>
      <v-text-field
        v-model="formData.shippingStreet2"
        :rules="[rules.required]"
        label="Street 2"
        maxlength="100"
        required
        dense
      ></v-text-field>
      <v-text-field
        v-model="formData.shippingCity"
        :rules="[rules.required]"
        label="City"
        maxlength="50"
        required
        dense
      ></v-text-field>
      <v-text-field
        v-model="formData.shippingState"
        :rules="[rules.required]"
        label="County"
        maxlength="50"
        required
        dense
      ></v-text-field>
      <v-text-field
        v-model="formData.shippingPostcode"
        :rules="[rules.required]"
        label="Postcode"
        maxlength="10"
        required
        dense
      ></v-text-field>
      <v-text-field
        v-model="formData.shippingCountry"
        :rules="[rules.required]"
        label="Country"
        maxlength="50"
        required
        dense
      ></v-text-field>
    </v-card-text>
  </v-card>
</template>

<script>

export default {
  props: {
    formData: Object,
    rules: Object
  },
  data: () => ({
    sameAsBilling: false
  }),
  methods: {
    toggleSameAsBilling (val) {
      if (val) {
        this.formData.shippingStreet = this.formData.billingStreet
        this.formData.shippingStreet2 = this.formData.billingStreet2
        this.formData.shippingCity = this.formData.billingCity
        this.formData.shippingState = this.formData.billingState
        this.formData.shippingPostcode = this.formData.billingPostcode
        this.formData.shippingCountry = this.formData.billingCountry
      }
    }

  }
}
</script>
